<template>
  <div class="index">
    <div class="file-container">
      <div class="nav-title">文件</div>
      <div class="file-list">
        <div class="date">今天</div>
        <div v-for="(item, index) in fileList" :key="index" class="file-item">
          <div class="file-item-left">
            <img :src="item.img" />
            <span class="item-name">{{ item.name }}</span>
            <span class="item-size">{{ item.size }}</span>
          </div>
          <div class="file-item-right">
            <span>{{ item.time }}</span>
            <span>来自：{{ item.userName }}</span>
          </div>
        </div>
        <div class="date">2020年11月</div>
        <div v-for="(item, sindex) in fileList" :key="sindex" class="file-item">
          <div class="file-item-left">
            <img :src="item.img" />
            <span class="item-name">{{ item.name }}</span>
            <span class="item-size">{{ item.size }}</span>
          </div>
          <div class="file-item-right">
            <span>{{ item.time }}</span>
            <span>来自：{{ item.userName }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fileList: [
        {
          name: "自动发单机器人云管理后台.pdf",
          size: "45M",
          time: "16:16",
          userName: "小李",
          img: require("@/assets/images/ic_pdf@3x.png"),
        },
        {
          name: "自动发单机器人.pdf",
          size: "45M",
          time: "16:16",
          userName: "老赵",
          img: require("@/assets/images/ic_docx@3x.png"),
        },
      ],
    };
  },
  created() {},
  methods: {},
};
</script>

<style scoped>
.index {
  display: flex;
  flex: 1;
}
.file-container {
  width: 760px;
  height: 100%;
  background: #ffffff;
}
.file-container .nav-title {
  height: 60px;
  line-height: 60px;
  padding: 0 10px;
  font-size: 18px;
  color: #313233;
  background: #f5f7fa;
  border-bottom: 1px solid #dcdfe6;
  box-sizing: border-box;
}
.file-list {
  height: 540px;
  padding-left: 12px;
  box-sizing: border-box;
}
.file-list .date {
  font-size: 14px;
  color: #7b7d80;
  padding: 12px 0;
  box-sizing: border-box;
}
.file-item {
  height: 65px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid #dcdfe6;
}
.file-item .file-item-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.file-item .file-item-left img {
  width: 40px;
  height: 40px;
}
.file-item-left .item-name {
  font-size: 16px;
  color: #313233;
  margin-left: 12px;
  margin-right: 8px;
}
.file-item-left .item-size {
  font-size: 12px;
  color: #313233;
}
.file-item .file-item-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  margin-right: 12px;
  font-size: 12px;
  color: #abaeb3;
}
</style>